import { Navigate, useLocation, useParams } from 'react-router-dom';
import { flat } from 'app/utils/utils';
import AllPages from '../routes';

const getRoutePath = (pathname, params) => {
  
	if (!Object.keys(params).length) {
	  	return pathname; // we don't need to replace anything
	}
  
	let path = pathname;
	Object.entries(params).forEach(([paramName, paramValue]) => {
		if (paramValue) {
			path = path.replace(paramValue, `:${paramName}`);
		}
	});
	return path;
};

const userHasPermission = (pathname, user, routes) => {
	if (!user) {
		return false;
	}
	const matched = routes.find((r) => r.path === pathname);

	const authenticated = matched && matched.auth && matched.auth.length 
		? matched.auth.includes(user.role) 
		: false;
	return authenticated;
};

const AuthGuard = ({ children }) => {
	const params = useParams();
	const { pathname } = useLocation();

	const path = getRoutePath(pathname, params);

	const authenticatedUser = JSON.parse(localStorage.getItem("authenticatedUser"));

	const routes = flat(AllPages);

	const hasPermission = userHasPermission(path, authenticatedUser, routes);

	// // IF YOU NEED ROLE BASED AUTHENTICATION,
	// // UNCOMMENT ABOVE LINES
	// // AND COMMENT OUT BELOW authenticated VARIABLE

	return (
		<>
			{ hasPermission ? (
				children
			) : authenticatedUser ? (
				<Navigate replace to="/session/unauthorized" state={{ from: pathname }} />
			) : (
				<Navigate replace to="/session/signin" state={{ from: pathname }} />
			)}
		</>
	);
};

export default AuthGuard;
