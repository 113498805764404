import axios from 'axios';

const baseUrlDev = 'http://localhost:5000/api/v1';
// const baseUrlPro = 'https://soba-api.soba.africa/api/v1';
const baseUrlPro = 'https://apinode.soba.africa/api/v1';

const baseURL =
	window.location.hostname.includes("dev") || window.location.hostname.includes("localhost")
		? baseUrlDev
		: baseUrlPro;

const instance = axios.create({
	baseURL: baseURL,
	timeout: 30000,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

export const axiosPrivate = axios.create({
	baseURL: baseURL,
	timeout: 30000,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
});

export default instance;
